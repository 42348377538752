.table-header {
    width: 100%;
  }
  
  .MuiTableContainer-root {
    background-color: aquamarine;
  }
  .MuiTable-root{
    border: #c1bbbb 1px solid;
  }
  .table-layout {
    /* background-color: aquamarine; */
    width: 92%;
    margin-left: 4.5%;
    margin-top: -8px;
    display: grid;
    /* justify-content: center; */
    /* display: none; */
  }
  .backbtn{
    height: 45px;
    width: 7%;
    margin-bottom: 29px;
    cursor: pointer;
  }
  
.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  /* border-bottom: 1px solid #dee2e6; */
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }
    .modal-scrolling {
      max-height: calc(100vh - 210px);
      overflow-y: auto; }
    
    .modal-footer-hack {
      background-color: #f8f9fa;
      border-top: 1px solid #e9ecef;
      padding: 10px 16px; }
    
    .modal-body {
      padding-top: 0; }
      .close {
        color: grey;
        float: right;
        font-size: 24px;
        font-weight: bold;
        cursor:pointer;
      }
      .input-label {
        font-size: x-large;
      }
.selectbox{
    margin-top: 0px;
    width: 100%;
    color: #645e5e;
    height: 54px;
    padding: 9px;
    border-color: #b5b0b0;
    border-radius: 4px;
    cursor: pointer;
}
.infoicon{
  cursor: pointer;
  font-size: 18px;
  color: #7d7d7d;
  padding-left: 20px;
  margin-bottom: -3px;
}

.loader {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  animation: rotate 1s linear infinite
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3.5px solid rgb(236, 169, 53);
  animation: prixClipFix 2s linear infinite ;
}
.vin{
  margin-bottom: 28px;
    font-size: 19px;
    font-weight: 500;
}
@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
.headercomp{
  color: rgba(0, 0, 0, 0.7);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  border-bottom: solid 0.7px rgba(0, 0, 0, 0.26);
  padding: 1rem;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  line-height: 1.75;
  text-transform: uppercase;
}