.datetime-picker {
    width: 200px;
    /* background-color: green; */
}

#datetimepicker {
    width: 200px !important;
}

.graph-dashboard-card {
    background-color: #EAECEE;
    padding: 16px;
    margin: 16px;
}

/* .graph-name {
    background-color: bisque;
} */