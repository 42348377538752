.App {
  height: 100vh;
}

.header-component{
  color: rgba(0, 0, 0, 0.7);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  border-bottom: solid 0.7px rgba(0, 0, 0, 0.26);
  padding: 1.5rem;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  line-height: 1.75;
  text-transform: uppercase;
}

#viewport {
  width: calc(100vw - 6.25rem);
  margin-left: 6.25rem;
}

#main-content {
  height: calc(100vh - (0.875rem + 3rem + 3.5rem));
  overflow-y: auto;
  padding: 1.5rem;
}