#form-container {
  width: 45em;
  margin: auto;
  max-width: 80vw;
}

.input-label {
  font-size: small;
  color: #555;
  margin-bottom: 0.5rem;
}

.ant-upload-btn {
  padding: 0.75em !important;
}

.ant-upload-list {
  width: max-content;
}

.ant-upload-drag-icon {
  margin-top: 0.5em;
  margin-bottom: 0.5em !important;
}

.ant-upload-hint {
  margin: 0;
}

.ant-select-selection-item {
  font-weight: bold !important;
}

.radio-options {
  padding: 0 1em;
}