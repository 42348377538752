/* Sidebar.css */

.sidebar {
  /* width: 6.25rem;
    background-color: #333;
    color: white;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column; */
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.sub_division {
  background-color: rgb(33, 33, 33);
  border-top-right-radius: 0.5em 1em;
  box-shadow: none;
  display: flex;
  height: 100vh;
  width: 13rem;
  z-index: 1200;
  top: 0px;
  outline: 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  transform: none;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  justify-content: center;
  color: rgba(0, 0, 0, 0.87);
  /* cursor: pointer; */
  left: 99px;
  /* flex-direction: column; */
  padding-top: 110px;
  overflow: visible;
  position: fixed;
}

.main_sidebar {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-shrink: 0;
  inset: 0px 0px auto;
  color: rgb(255, 255, 255);
  position: fixed;
  height: 100%;
  width: 6.25rem;
  z-index: 1201;
  box-shadow: none;
  background-color: rgb(33, 33, 33);
  padding: 0px;
  background-image: none;

}

.sub_division_hidden {
  background-color: #333;
  border-top-right-radius: 0.5em 1em;
  box-shadow: none;
  display: contents;
  transform: translateX(-333px);
  visibility: hidden;
  height: 100vh;
  width: 15rem;
  z-index: 1200;
  top: 0px;
  outline: 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  justify-content: center;
  color: white;
  cursor: pointer;
  overflow: visible;
  flex-direction: column;
}

.sub_item {
  width: 100%;
  /* text-align: center; */
  margin-right: 6px;
  transition: width 600ms ease 0s;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  text-align: start;
}

.sidebar-hiden {
  display: none;
}

.item {
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.0075em;
  font-family: "Encode Sans Expanded", Lato, sans-serif;
  width: 14.375rem;
  margin: 0.875rem 0px 0.875rem 0.875rem;
  color: white !important;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.813rem;
  padding: 11px;

}

.item:hover {
  border-radius: 1.85rem;
  border-width: 10px;
  /*  */
  background-color: #555;
  padding: 11px;
}

.sub-nav-list {
  list-style: none;
}

.sub {
  line-height: 2.813rem;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.0075em;
  font-family: "Encode Sans Expanded", Lato, sans-serif;
  width: 14.375rem;
  margin: 0.875rem 0px 0.875rem 0.875rem;
  color: white !important;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin-top: 30px !important;
  /* display: flex;
    justify-content: center; */
}

.nav-item {
  /* margin-bottom: 10px; */
  cursor: pointer;
  padding: 5px 0px 5px 5px;
  transition: background-color 0.3s;
}

.icon:hover {
  border-radius: 1.85rem;
  background-color: #555;
  padding: 0px 11px 0px 11px;
}

.sub-nav-list-option {
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.0075em;
  font-family: "Encode Sans Expanded", Lato, sans-serif;
  width: 14.375rem;
  margin: 0.875rem 0px 0.875rem 0.875rem;
  color: white !important;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.813rem;
}

.sub-nav-list-option:hover {
  border-radius: 8px;
  border-width: 10px;
  /*  */
  background-color: #555;
  width: 14.375rem;
}


.sidebar {
  /* width: 100px; */
  /* height: 100px; */
  /* background-color: red; */
  /* position: relative; */
  animation-name: sidebar;
  animation-duration: 0.1s;
}

@keyframes sidebar {
  0% {
    left: -200px;
    top: 0px;
  }

  25% {
    left: -150px;
    top: 0px;
  }

  50% {
    left: -100px;
    top: 0px;
  }

  75% {
    left: -50px;
    top: 0px;
  }

  100% {
    left: 0px;
    top: 0px;
  }
}

.sidebar-hiden {
  /* width: 100px; */
  /* height: 100px; */
  /* background-color: red; */
  /* position: relative; */
  animation-name: sidebarhidden;
  transition-duration: 4s;
}

.dev_p {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: white;
  font-size: 1.25rem;
  letter-spacing: 0.063rem;
  text-shadow: white 0.094rem 0px;
  text-align: center;
  line-height: 1.2;
}

.dev {
  width: 100%;
  height: 1.5rem;
}

.app_name {
  margin: 0px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 2.25rem;
  letter-spacing: 0.063rem;
  font-family: "Encode Sans Expanded", Lato, sans-serif;
  text-align: center;
}

.iconName {
  text-align: center;
  line-height: 0.75rem;
  word-break: break-word;
  font-size: 0.75rem;
  letter-spacing: -0.047rem;
  margin: 0px 0.125rem 0.125rem;
  /* padding-top: 10px; */
}

.icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.thirddiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  -webkit-box-pack: end;
  -webkit-box-flex: 1;
  margin-bottom: 0.75rem
}

/* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    top:1100px !important;
    left:30px !important
  } */

@keyframes sidebarhidden {
  0% {
    left: 0px;
    top: 0px;
  }

  25% {
    left: -50px;
    top: 0px;
  }

  50% {
    left: -100px;
    top: 0px;
  }

  75% {
    left: -150px;
    top: 0px;
  }

  100% {
    left: -200px;
    top: 0px;
  }
}

#logout-popup {
  position: absolute;
  top: -3.5em;
  left: 0.25em;
  transition: all 0.3s ease;
  border-radius: 0.25em;
  text-align: center;
  width: 5em;
  background-color: white;
  color: black;
  padding: 0.75em 0.25em;
  border: 1px solid #aaa;
  cursor: pointer;
}

#logout-popup:hover {
  background-color: #ccc;
}