#form-container {
  width: 45em;
  margin: auto;
  max-width: 80vw;
}

.input-label {
  font-size: small;
  color: #555;
  margin-bottom: 0.5rem;
}

.radio-options {
  padding: 0 1em;
}

td {
  padding: 0.25em 1rem;
  font-weight: 500;
  font-size: smaller;
}

.ada-link {
  cursor: pointer;
  margin-right: 1em;
  transition: all 0.3s ease-in-out;
  /* font-size: smaller; */
  color: navy;
  padding: 0.1em 0.5em;
  border-radius: 0.5em;
}

.ada-link:hover{
  color:blueviolet;
  background-color: rgba(0,0,0,0.05);
}

.json {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 1rem;
  background-color: #234;
  color: khaki;
  line-height: 1.25;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-collapse-header {
  padding: 4px 8px !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0 !important;
}

.ant-tabs-tab {
  padding: 2px 0 !important;
}

/* .ant-tabs-card >div>.ant-tabs-nav .ant-tabs-tab-active {
  background-color: #eee !important;
  font-size: small;
} */

.ant-tabs-bottom >div>.ant-tabs-nav::before {
  border-bottom: 1px solid #eee !important;
}

.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-active) {
  background-color: #0001;
}

.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab {
  font-size: small;
}

.ant-tabs-tab-btn {
  padding: 0 0.5rem !important;
}

.ant-tabs-nav {
  margin-bottom: 4px !important;
}

.ant-checkbox-group-item {
  width: 100%;
}

.ant-tag.fname {
  font-size: 0.9em;
  line-height: 1.5em;
  font-weight: 400;
  cursor: pointer;
}

.ant-tree-list-holder-inner {
  font-size: 0.8em;
}

.ant-tree-treenode {
  padding-bottom: 0 !important;
  height: 1.75em;
}

.ant-tree-switcher-icon {
  vertical-align: middle !important;
}

.ant-tree-switcher {
  margin-bottom: -5px !important;
}

.ant-tree-node-selected {
  background-color: rgba(0,0,0,0.05) !important;
}