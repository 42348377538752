.title-strip {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
}

.hover-effect {
  font-size: 40px;
  cursor: pointer; 
  color: black; 
  transition: box-shadow 0.3s ease; 
  width: fit-content;

}

.hover-effect:hover {
  box-shadow: 0px 4px rgba(126, 125, 125, 0.3); 
}
  
