body {
  zoom: 1;
}

.large-icon {
  font-size: 2rem;
  padding: 0.5rem 0 0.25rem;
}

.custom-large-icon {
  height: 32px;
  padding: 0.5rem 0 0;
}

.small-icon {
  /* filter: brightness(0) saturate(0%); */
  font-size: 0.8rem;
  padding: 0;
}

.custom-small-icon {
  /* filter: brightness(0) saturate(0%); */
  height: 14px;
  padding: 0.1em 0 0;
}

.status-wrapper {
  width: 4rem;
}

.update-row {
  padding: 1em !important;
  border-color: #ddd !important;
  justify-content: flex-start !important;
}

.update-row>ul {
  margin-inline-start: 1rem !important;
}

.status-label {
  color: #777;
  font-style: italic;
  margin-bottom: 0.25rem;
}

.op-label {
  text-align: center;
  min-width: 8rem;
}

.region-label {
  color: #bbb;
}

.region-label.active {
  font-weight: bold;
  color: rgb(36, 55, 130);
}

.inputs-divider {
  margin: 2px 0 6px -1em;
  width: 7rem;
}

.update-time {
  font-size: 1rem;
  letter-spacing: 0.01em;
  font-weight: 600;
  line-height: 2rem;
}

.update-description {
  font-size: 1em;
  line-height: 2rem;
  color: #666;
}

.inputs-section {
  min-width: 6rem;
  padding-left: 1rem;
  margin-top: -0.25em;
}

.details-section {
  min-width: 30rem;
  padding: 0 1.5em; 
  margin: -0.5em 0;
}

.steps-divider {
  height: 6rem;
  margin-right: 2rem;
}

.steps-wrapper {
  display: flex;
  align-items: center;
}

.steps-progress {
  display: none;
}

#search-and-filter {
  width: calc(100% - 3em);
  border: 1px solid #ddd;
  border-radius: 0.5em;
  margin-bottom: 0.5em;
  margin-top: -1em;
}

.ant-list-pagination.ant-list-pagination-align-end {
  margin-bottom: 0 !important;
}


@media screen and (max-width: 1479px) {
  .steps-wrapper {
    display: none;
  }

  .steps-progress {
    display: unset;
  }
}

@media screen and (max-width: 1860px) {
  .details-section {
    min-width: 20rem;
    font-size: small;
    padding: 0 1em; 
    margin: -0.5em auto -0.5em 0;
  }
  
  .update-time {
    font-size: 1.125em;
  }

  .op-label {
    text-align: center;
    min-width: 4rem;
    max-width: 4rem;
  }

  .status-label {
    font-size: 0.875em;
  }

  .status-wrapper {
    width: 3.6rem;
  }
  
  .ant-list-item-meta-avatar {
    margin-inline-end: 4px !important;
  }

  .inputs-section {
    min-width: 5rem;
    padding-left: 0.25rem;
    margin-top: -0.25em;
  }

  .inputs-divider {
    margin: 2px 0 2px -10%;
    width: 115%;
  }
  
  .steps-divider {
    margin-right: 1rem;
  }

  .ant-steps-item-content {
    width: 100px !important;
    padding-left: 8px;
  }
}