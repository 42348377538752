#cards-container {
  display: flex;
  align-items: center;
  height: auto;
  justify-content: space-around;
}

.nav-card {
  height: 10em;
  width: 23em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #243782;
  background-color: #24378211;
  border-radius: 0.5em;
  font-size: 1.25em;
  color: #243782;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  font-weight: bold;
  /* letter-spacing: 0.1ch; */
}

.nav-card:hover {
  color: white;
  background-color: #243782;
  font-weight: bold;
  /* text-transform: uppercase; */
}

.nav-card-title {
  width: 15rem;
  text-align: center;
  transition: all 0.15s ease-in-out;
}

.nav-card-description {
  font-size: 1rem;
  text-transform: none;
  position: absolute;
  margin-top: 1em;
  transition: all 0.15s ease-in-out;
  font-weight: normal;
  text-align: center;
  width: 15em;
  opacity: 0;
}

.nav-card:hover > div > .nav-card-title {
  margin-top: -2.5em;
}

.nav-card:hover > div > .nav-card-description {
  opacity: 1;
  color: white;
}